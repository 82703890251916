import dateFormat from 'dateformat';
import moment from 'moment';
import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { breakTimes } from '../../../../constants';
import { UserProfession, UserSubProfession } from '../../../../enums/UserProfession';
import { getShouldAllowAdminToPostProcedure } from '../../../../growthbook';
import '../../../../themes/modalWindow.scss';
import { filterObjectKeys } from '../../../../utils';
import { EducationUtils } from '../../../../utils/EducationUtils';
import MultiSelect from '../../../commonComponents/MultiSelect';
import ModalJobEdit from '../../Modal';

class JobDetailsBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offerStartTime: moment(props.job_detail.local_start_time, 'hh:mm A').format('HH:mm'),
      offerEndTime: moment(props.job_detail.local_end_time, 'hh:mm A').format('HH:mm'),
      lunch_break_time: props.job_detail.lunch_break_time,
      is_lunch_break_paid: props.job_detail.is_lunch_break_paid,
      rate: props.job_detail.rate,
      offerDate: props.job_detail.job_datetime,
      enableAutoFill: props.job_detail.autoFill,
      enableAutoFillFavorites: !!props.job_detail.autofillOptions?.favorite,
      enableAutoFillHighlyRated: !!props.job_detail.autofillOptions?.highlyRated,
      skills: props.job_detail.specialty,
      selectedProcedures: props.job_detail.procedures,
      isAssistedHygiene: props.job_detail.isAssistedHygiene,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.skills !== prevState.skills) {
      this.setState({
        selectedProcedures: [],
      });
    }
  }

  getAvailableProcedures() {
    const { userEducation, job_detail: job } = this.props;
    const { skills } = this.state;

    return EducationUtils.getProceduresOfProfession({
      specialtiesAndProcedures: userEducation?.specialtiesAndProcedures,
      profession: job.profession,
      specialty: skills,
    });
  }

  renderMultiSelectBox(data, type) {
    const { lunch_break_time: lunchBreakTime, specializations } = this.state;

    return (
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: '5px 10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <p className="card-text" style={{ fontWeight: '600' }}>
          {type === 'break' ? 'Meal Break Time' : 'Specializations'}
        </p>

        <select
          className="form-control"
          style={{ width: 100 }}
          value={type === 'break' ? lunchBreakTime : specializations}
          onChange={(e) =>
            this.setState(
              type === 'break'
                ? { lunch_break_time: e.target.value }
                : { specializations: [e.target.value] },
            )
          }
        >
          {data.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
      </div>
    );
  }

  renderSkillsMultiSelectBox() {
    const { userEducation } = this.props;

    const availableSpecialties = Object.keys(filterObjectKeys(userEducation?.specialtiesAndProcedures, 'specialty'));

    return (
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: '5px 10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <p className="card-text" style={{ fontWeight: '600' }}>
          Skills
        </p>

        <select
          className="form-control"
          style={{ width: 'auto' }}
          value={this.state.skills}
          onChange={(e) => this.setState({ skills: e.target.value })}
        >
          {availableSpecialties.map((code) => (
            <option value={code}>{code?.split('_')?.[1]}</option>
          ),
          )}
        </select>
      </div>
    );
  }

  onSave = () => {
    const {
      offerStartTime,
      offerEndTime,
      offerDate,
      is_lunch_break_paid: isLunchBreakPaid,
      rate,
      lunch_break_time: lunchBreakTime,
      enableAutoFill,
      enableAutoFillFavorites,
      enableAutoFillHighlyRated,
      skills,
      selectedProcedures,
      isAssistedHygiene,
    } = this.state;

    const { onSave, onCancel, visibleModal, job_detail: jobDetail } = this.props;

    const startArr = offerStartTime.split(':');
    const endArr = offerEndTime.split(':');
    const jobDateTime = moment(offerDate).set({ hour: startArr[0], minute: startArr[1] }).toDate();
    const jobDateTimeEnd = moment(offerDate).set({ hour: endArr[0], minute: endArr[1] }).toDate();

    const jobDateStart = moment(
      `${jobDetail?.local_date} ${jobDetail?.local_start_time}`,
      'ddd, MMM DD, YYYY hh:mm a',
    ).toISOString();
    const jobDateEnd = moment(
      `${jobDetail?.local_date} ${jobDetail?.local_end_time}`,
      'ddd, MMM DD, YYYY hh:mm a',
    ).toISOString();

    const newDates =
      jobDateTime.toISOString() !== jobDateStart || jobDateTimeEnd.toISOString() !== jobDateEnd
        ? { job_datetime: jobDateTime, job_datetime_end: jobDateTimeEnd }
        : {};
    const newRate = jobDetail.rate !== rate ? { rate } : {};
    const newIsBreakPaid =
      jobDetail.is_lunch_break_paid !== isLunchBreakPaid
        ? { is_lunch_break_paid: isLunchBreakPaid }
        : {};
    const newIsAssistedHygiene =
      jobDetail.isAssistedHygiene !== isAssistedHygiene
        ? { isAssistedHygiene }
        : {};
    const newBreakTime =
      jobDetail.lunch_break_time !== lunchBreakTime ? { lunch_break_time: lunchBreakTime } : {};
    const newSkills = jobDetail.skills !== skills ? { specialty: skills } : {};
    const newAutoFill = jobDetail.autoFill !== enableAutoFill ? { autoFill: enableAutoFill } : {};
    
    const hasAutoFillChanged = 
      !!jobDetail.autofillOptions?.favorite !== enableAutoFillFavorites ||      
      !!jobDetail.autofillOptions?.highlyRated !== enableAutoFillHighlyRated;
    const autofillOptions = hasAutoFillChanged
      ? {
        autofillOptions : {
          favorite: enableAutoFillFavorites,
          highlyRated: enableAutoFillHighlyRated,
        }
      }
      : {};

    onSave({
      ...newDates,
      ...newRate,
      ...newIsBreakPaid,
      ...newIsAssistedHygiene,
      ...newBreakTime,
      ...newSkills,
      ...newAutoFill,
      ...autofillOptions,
      procedures: selectedProcedures,
    });

    onCancel();
    visibleModal();
  };

  render() {
    let preferType = '-';

    const { job_detail: jobDetail } = this.props;

    if (jobDetail.prefer_type === 'more_experience') {
      preferType = 'Most Experience';
    } else if (jobDetail.prefer_type === 'first_available') {
      preferType = '1st Available';
    } else if (jobDetail.prefer_type === 'closer_to_me') {
      preferType = 'Closer To Me';
    }

    const {
      offerStartTime,
      offerEndTime,
      offerDate,
      is_lunch_break_paid: isLunchBreakPaid,
      lunch_break_time: lunchBreakTime,
      rate,
      enableAutoFill,
      enableAutoFillFavorites,
      enableAutoFillHighlyRated,
      selectedProcedures,
      skills,
      isAssistedHygiene,
    } = this.state;

    return (
      <>
        {this.props.isVisibleModal ? (
          <ModalJobEdit
            job_detail={jobDetail}
            editRate={Number(rate)}
            editOfferStartTime={offerStartTime}
            editOfferEndTime={offerEndTime}
            editOfferDate={offerDate}
            editIsLunchBreakPaid={isLunchBreakPaid ? 'Yes' : 'No'}
            editIsAssistedHygiene={isAssistedHygiene ? 'Yes' : 'No'}
            editLunchBreakTime={lunchBreakTime}
            editAutoFill={enableAutoFill ? 'On' : 'Off'}
            editAutoFillFavorites={enableAutoFillFavorites ? 'On' : 'Off'}
            editAutoFillHighlyRated={enableAutoFillHighlyRated ? 'On' : 'Off'}
            editSkills={skills}
            procedures={selectedProcedures}
            onSave={this.onSave}
            closeModal={this.props.visibleModal}
          />
        ) : null}
        <div
          className={this.props.isVisibleModal ? 'card mt-4 mb-4 blur ' : 'card mt-4 mb-4'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '80%',
            maxWidth: 800,
            marginTop: 50,
          }}
        >
          <div className="card-body" style={{ width: '100%' }}>
            <div>
              <h3 className="card-title" style={{ textAlign: 'center' }}>
                Job Details
              </h3>
            </div>
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Job ID
              </p>
              <p className="card-text">{jobDetail?.transaction_id || ''}</p>
            </div>
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Profession
              </p>
              <p
                className="card-text">{UserSubProfession.getName(jobDetail?.subProfession) ?? jobDetail?.professionName ?? '-'}</p>
            </div>

            {this.renderSkillsMultiSelectBox()}

            {
              getShouldAllowAdminToPostProcedure() && this.getAvailableProcedures().length > 0 && (
                <div
                  style={{
                    width: '100%',
                    borderBottom: '1px solid #efefef',
                    padding: '5px 10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p className="card-text" style={{ fontWeight: '600' }}>
                    Procedures
                  </p>

                  <MultiSelect
                    className="custom-select custom-select-lg mb-3"
                    style={{ position: 'relative', width: 240 }}
                    placeholder="Procedures"
                    alwaysShowPlaceholder
                    options={this.getAvailableProcedures().map((procedure) => {
                      const name = procedure.replace(/^procedure_/g, '');

                      return {
                        label: name,
                        value: procedure,
                      }
                    })}
                    autoApply={false}
                    maxSelection={4}
                    value={selectedProcedures}
                    onChange={(values) => {
                      this.setState({
                        selectedProcedures: values,
                      })
                    }}
                  />

                </div>
              )
            }


            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Created
              </p>
              <p className="card-text">
                {jobDetail
                  ? dateFormat(jobDetail.createdAt, 'dddd, mmmm dS, yyyy, h:MM:ss TT')
                  : ''}
              </p>
            </div>
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Filled
              </p>
              <p className="card-text">
                {jobDetail?.job_filled_datetime
                  ? dateFormat(jobDetail?.job_filled_datetime, 'dddd, mmmm dS, yyyy, h:MM:ss TT')
                  : '-'}
              </p>
            </div>

            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Start Date
              </p>
              <DayPickerInput
                className="form-control"
                keepFocus={false}
                onDayChange={(e) =>
                  this.setState({
                    offerDate: dateFormat(e, 'ddd, mmmm dd, yyyy'),
                  })
                }
                value={dateFormat(offerDate, 'ddd, mmmm dd, yyyy')}
              />
            </div>
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Start Time
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}
              >
                <input
                  type="time"
                  className="form-control"
                  value={offerStartTime}
                  onChange={(e) => this.setState({ offerStartTime: e.target.value })}
                />
              </div>
            </div>
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                End Time
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}
              >
                <input
                  type="time"
                  className="form-control"
                  value={offerEndTime}
                  onChange={(e) => this.setState({ offerEndTime: e.target.value })}
                />
              </div>
            </div>
            {jobDetail.original_rate ? (
              <div
                style={{
                  width: '100%',
                  borderBottom: '1px solid #efefef',
                  padding: '5px 10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p className="card-text" style={{ fontWeight: '600' }}>
                  Original Rate
                </p>
                <p className="card-text">{jobDetail.original_rate.toFixed(2) || ''}</p>
              </div>
            ) : (
              <div />
            )}
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Rate
              </p>
              <input
                style={{ width: 100 }}
                className="form-control"
                type="number"
                value={rate}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => this.setState({ rate: e.target.value })}
              />
            </div>
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Meal Break Paid
              </p>
              <select
                className="form-control"
                style={{ width: 100 }}
                value={isLunchBreakPaid ? 'Yes' : 'No'}
                onChange={(e) => this.setState({ is_lunch_break_paid: e.target.value === 'Yes' })}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            {this.renderMultiSelectBox(breakTimes, 'break')}

            {jobDetail?.profession === UserProfession.RDH && 
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Assisted Hygiene Available
              </p>
              <select
                className="form-control"
                style={{ width: 100 }}
                value={isAssistedHygiene ? 'Yes' : 'No'}
                onChange={(e) => this.setState({ isAssistedHygiene: e.target.value === 'Yes' })}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            }

            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Auto-Confirm (Favorites)
              </p>
              <select
                className="form-control"
                style={{ width: 100 }}
                value={enableAutoFillFavorites}
                onChange={(e) => this.setState({ enableAutoFillFavorites: e.target.value === 'true' })}
              >
                <option value>On</option>
                <option value={false}>Off</option>
              </select>
            </div>

            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Auto-Confirm (Highly-Rated)
              </p>
              <select
                className="form-control"
                style={{ width: 100 }}
                value={enableAutoFillHighlyRated}
                onChange={(e) => this.setState({ enableAutoFillHighlyRated: e.target.value === 'true' })}
              >
                <option value>On</option>
                <option value={false}>Off</option>
              </select>
            </div>

            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Auto-Confirm (All Eligible)
              </p>
              <select
                className="form-control"
                style={{ width: 100 }}
                value={enableAutoFill}
                onChange={(e) => this.setState({ enableAutoFill: e.target.value === 'true' })}
              >
                <option value>On</option>
                <option value={false}>Off</option>
              </select>
            </div>

            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Dentist Prefer
              </p>
              <p className="card-text">{`${preferType}` || ''}</p>
            </div>

            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Cancelled
              </p>
              <p className="card-text">
                {jobDetail?.is_cancelled
                  ? dateFormat(jobDetail?.job_cancelled_datetime, 'dddd, mmmm dS, yyyy, h:MM:ss TT')
                  : 'No' || ''}
              </p>
            </div>
            {jobDetail?.is_cancelled ? (
              <div>
                <div
                  style={{
                    width: '100%',
                    borderBottom: '1px solid #efefef',
                    padding: '5px 10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p className="card-text" style={{ fontWeight: '600' }}>
                    Cancelled by
                  </p>
                  <p className="card-text">
                    {`${jobDetail?.cancelled_by?.first_name || ''} ${
                      jobDetail?.cancelled_by?.last_name || ''
                    }`}
                  </p>
                </div>
                <div
                  style={{
                    width: '100%',
                    borderBottom: '1px solid #efefef',
                    padding: '5px 10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p className="card-text" style={{ fontWeight: '600' }}>
                    Hours to the shift
                  </p>
                  <p className="card-text">
                    {moment(jobDetail?.job_datetime).diff(
                      moment(jobDetail?.job_cancelled_datetime),
                      'hours',
                    )}
                  </p>
                </div>
              </div>
            ) : (
              <div />
            )}
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Reposted
              </p>
              <p className="card-text">{jobDetail?.repost ? 'Yes' : 'No'}</p>
            </div>
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Urgent Shift
              </p>
              <p className="card-text">{jobDetail?.urgentShift ? 'Yes' : 'No'}</p>
            </div>
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Extra Pay
              </p>
              <p className="card-text">
                {jobDetail?.urgentShift?.bonusPay?.toFixed(2) ?? '-'}
              </p>
            </div>
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Posted By
              </p>
              <p className="card-text">{this.props.theOneWhoPosted(jobDetail?.author)}</p>
            </div>
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #efefef',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600' }}>
                Professional Confirmation
              </p>
              <p className="card-text">
                {jobDetail?.confirmAttendance?.confirmationDate
                  ? dateFormat(
                    jobDetail?.confirmAttendance?.confirmationDate,
                    'dddd, mmmm dS, yyyy, h:MM:ss TT',
                  )
                  : '-'}
              </p>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginBottom: 20,
            }}
          >
            <button
              type="button"
              onClick={() => this.props.onCancel()}
              className="btn btn-secondary"
              style={{ marginTop: '12px', marginRight: '6px', width: 100 }}
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={() => this.props.visibleModal(true)}
              className="btn btn-danger"
              style={{ marginTop: '12px', marginLeft: '6px', width: 100 }}
            >
              Save
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default JobDetailsBox;
