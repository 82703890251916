import { DSO_MANAGER_FILTER, PRE_APPROVED_FILTER, PROFESSION_FILTER, RAM_VERIFIED_FILTER, STATES_FILTER, STATUS_FILTER, SUB_PROFESSION_FILTER, USER_TYPE_FILTER, WORKER_TYPE_FILTER, WORKER_TYPE_REQUEST_FILTER } from "../../../utils/filters";

export const filterKeys = [
  'page',
  'order',
  'sortField',
  'searchText',
  'phone',
  'device_type',
  'account_standing',
  'created_at',
  'last_time_sign_in',
  'info_filled',
  'contact_number',
  'address[0].city',
  'address[0].state',
  'address[0].zip',
  'average_rating',
  'ram',
  'dsoName',
  'dsoRamManager',
  'dsoManager',
  'status',
  'state',
  'workerType',
  'workerTypeRequest',
  'ramVerified',
  'profession',
  'subProfession',
  'requiredCredentials',
  'user_type',
  'dsoRamIds',
  'registrationStatus',
  'preApproved',
];

export const DNT_FILTERS = ({ dsoManagerList }) => [
  DSO_MANAGER_FILTER(dsoManagerList),
  STATUS_FILTER,
  STATES_FILTER,
  WORKER_TYPE_FILTER,
  WORKER_TYPE_REQUEST_FILTER,
  RAM_VERIFIED_FILTER,
  PRE_APPROVED_FILTER,
];

export const HYG_FILTERS = (allProfessions) => [
  STATUS_FILTER,
  STATES_FILTER,
  PROFESSION_FILTER(allProfessions),
  SUB_PROFESSION_FILTER,
];

export const UNFINISHED_USERS_FILTERS = (allProfessions) => [
  STATES_FILTER,
  PROFESSION_FILTER(allProfessions),
  SUB_PROFESSION_FILTER,
  USER_TYPE_FILTER,
];