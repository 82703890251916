import dateFormat from 'dateformat';
import moment from 'moment';
import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { createJob } from '../../../actions/jobsAction';
import { getUserEducation } from '../../../actions/licensesAction';
import { createErrorMessageSelector, createLoadingSelector } from '../../../apis/selectors';
import { userDaSubProfessions } from '../../../constants';
import { UserProfession } from '../../../enums/UserProfession';
import { UserType } from '../../../enums/UserType';
import { getShouldAllowAdminToPostProcedure } from '../../../growthbook';
import ArrowIcon from '../../../images/Right_arrow.png';
import '../../../themes/global.scss';
import '../../../themes/JobCreateComponent.scss';
import '../../../themes/verification.css';
import { filterObjectKeys } from '../../../utils';
import { EducationUtils } from '../../../utils/EducationUtils';
import { convertTimeToMinutes } from '../../../utils/String';
import MobileAppModal from '../../commonComponents/MobileAppModal';
import MultiSelect from '../../commonComponents/MultiSelect';
import JobDetailsUserBox from './JobDetailsUserBox';
import JobFindUserPlaceholder from './JobFindUserPlaceholder';

class JobCreateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dentist: undefined,
      hygienist: undefined,

      // job details
      rate: 36,
      breakTime: '',
      breakPaid: false,
      date: Date.now(),
      start: 'Time',
      end: 'Time',

      // presenting info
      modalContent: '',
      selectProfession: 'RDH',
      selectedSubProfession: null,
      skills: 'specialty_General Dentistry',
      selectedProcedures: []
    };
  }

  componentDidMount() {
    this.props.getUserEducation({
      userType: UserType.HYG,
      profession: this.state.selectProfession,
    });

    if (this.props.location.state?.user) {
      this.setState({ dentist: this.props.location.state?.user });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      selectProfession,
      skills,
    } = this.state;

    if (selectProfession !== prevState.selectProfession) {
      this.props.getUserEducation({
        userType: UserType.HYG,
        profession: selectProfession,
      });

      this.setState({
        skills: 'specialty_General Dentistry',
        selectedProcedures: []
      });
    } else if (skills !== prevState.skills) {
      this.setState({
        selectedProcedures: []
      });
    }
  }

  createJob = () => {
    const {
      hygienist, dentist, date, rate, start, end, breakTime, breakPaid, selectProfession, skills,
      selectedSubProfession,
      selectedProcedures,
    } = this.state;

    const localDate = moment(date).format('yyyy-MM-DD');
    const data = {
      offer_owner: dentist.id,
      localStart: start,
      localEnd: end,
      localDate,
      rate,
      break_time: convertTimeToMinutes(breakTime),
      is_break_paid: breakPaid,
      duplicate: true,
      prefer_type: 'first_availavle',
      hygienist: hygienist?._id ?? null,
      profession: selectProfession,
      subProfession: selectedSubProfession,
      specialty: skills,
      procedures: selectedProcedures,
    };

    this.props.createJob(data);
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  showContent = (type) => {
    this.setState({ modalContent: type, modalIsOpen: true });
  };

  modalResult = (val) => {
    if (this.state.modalContent === 'calendar') {
      this.setState({
        modalIsOpen: false,
        date: val,
      });
    } else if (this.state.modalContent === 'break') {
      this.setState({
        modalIsOpen: false,
        breakPaid: val.paid,
        breakTime: val.time,
      });
    } else if (this.state.modalContent === 'offer_rate') {
      this.setState({ modalIsOpen: false, rate: val });
    }
  };

  isValid() {
    return (
      this.state.dentist
      && this.state.date
      && this.state.start
      && this.state.end
      && this.state.breakTime
    );
  }

  renderDentalSearch() {
    if (this.state.dentist) {
      return (
        <JobDetailsUserBox
          user={this.state.dentist}
          onCancel={() => this.setState({ dentist: undefined })}
        />
      );
    }

    return (
      <JobFindUserPlaceholder
        title="+ Add a Dental Office"
        onSelectUser={(u) => {
          this.setState({ dentist: u });
        }}
        user_type="DNT"
      />
    );
  }

  renderHygienistSearch() {
    if (this.state.hygienist) {
      return (
        <JobDetailsUserBox
          user={this.state.hygienist}
          onCancel={() => this.setState({ hygienist: undefined })}
        />
      );
    }

    return (
      <JobFindUserPlaceholder
        title="+ Add a Hygienist"
        onSelectUser={(u) => this.setState({ hygienist: u })}
        user_type={UserType.HYG}
      />
    );
  }

  renderItem(title, value, action) {
    return (
      <div style={{ marginTop: 5 }} className="card form-field">
        <button
          type="button"
          className="card-body "
          onClick={() => this.showContent(action)}
        >
          <strong className="card-text label-title">
            {title}
          </strong>

          <h5 style={{ flex: 1, justifySelf: 'center' }} className="card-text">
            {value}
          </h5>
          <img alt="arrow" src={ArrowIcon} />
        </button>
      </div>
    );
  }

  getAvailableProcedures() {
    const { userEducation } = this.props;
    const { selectProfession, skills } = this.state;

    return EducationUtils.getProceduresOfProfession({
      specialtiesAndProcedures: userEducation?.specialtiesAndProcedures,
      profession: selectProfession,
      specialty: skills,
    });
  }

  render() {
    const {
      selectProfession,
      skills,
      date,
      breakTime,
      breakPaid,
      rate,
      modalIsOpen,
      modalContent,

      start,
      end,

      dentist,
      hygienist,
      selectedProcedures
    } = this.state;

    const buttonTitle = dentist && hygienist ? 'Send Offer' : 'Post a Job';

    const { allProfessions } = this.props.jobs;
    const { userEducation, isLoading } = this.props;

    const daSubProfessions = [{ code: 'none', name: 'None' }, ...userDaSubProfessions];
    const shouldShowSubProfessionOptions = selectProfession === UserProfession.DA;

    const availableSpecialties = Object.keys(filterObjectKeys(userEducation?.specialtiesAndProcedures, 'specialty'));

    return (
      <div
        className="job-create"
        style={{
          width: '100vw',
          height: '90vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          position: 'relative',
          marginTop: 20,
          marginBottom: 50,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginBottom: 20,
          }}
        >
          {this.renderDentalSearch()}
          {this.renderHygienistSearch()}
        </div>
        <div
          className="card"
          style={{
            width: '50%',
            paddingBottom: 12,
            paddingLeft: 12,
            paddingRight: 12,
            marginBottom: 60,
          }}
        >
          <div
            className="card-body full"
            style={{
              width: '100%',
            }}
          >
            <h3 className="card-title text-center">
              Job Details
            </h3>

            <FormField
              htmlFor="profession"
              title="Profession:"
            >
              <select
                id="profession"
                className="custom-select custom-select-lg mb-3"
                onChange={(e) => {
                  this.setState({
                    selectProfession: e.target.value,
                    selectedSubProfession: null,
                  });
                }}
              >
                {allProfessions.map((profession) => (
                  <option className="form-control"
                    key={profession.code}
                    value={profession.code}>
                    {profession.name}
                  </option>
                ))}
              </select>
            </FormField>

            {shouldShowSubProfessionOptions && (
              <FormField
                htmlFor="sub-profession"
                title="Sub-Profession:">

                <select
                  id="sub-profession"
                  className="custom-select custom-select-lg mb-3"
                  onChange={(e) => {
                    const code = e.target.value;

                    // sending null when code is 'none'/default
                    this.setState({
                      selectedSubProfession: code === 'none' ? null : code,
                    });
                  }}
                >
                  {daSubProfessions.map((subProfession) => (
                    <option className="form-control"
                      value={subProfession.code}>
                      {subProfession.name}
                    </option>
                  ))}
                </select>

              </FormField>
            )}

            <FormField
              htmlFor="skill"
              title="Preferred Skill:"
            >
              <select
                id="skill"
                className="custom-select custom-select-lg mb-3"
                onChange={(e) => this.setState({ skills: e.target.value })}
                value={skills}
              >
                {availableSpecialties.map((code) => (
                  <option className="form-control"
                    key={code}
                    style={{ textTransform: 'capitalize' }} value={code}>
                    {code?.split('_')?.[1]}
                  </option>
                ))}
              </select>
            </FormField>

            {getShouldAllowAdminToPostProcedure() && this.getAvailableProcedures().length > 0 && (
              <FormField
                htmlFor="procedures"
                title="Procedures:"
                optional
              >
                <MultiSelect
                  className="custom-select custom-select-lg mb-3"
                  style={{ position: 'relative'}}
                  placeholder="Procedures"
                  alwaysShowPlaceholder
                  options={this.getAvailableProcedures().map((procedure) => {
                    const name = procedure.replace(/^procedure_/g, '');

                    return {
                      label: name,
                      value: procedure,
                    }
                  })}
                  autoApply={false}
                  maxSelection={4}
                  value={selectedProcedures}
                  onChange={(values) => {
                    this.setState({
                      selectedProcedures: values,
                    })
                  }}
                />
              </FormField>
            )}


            {this.renderItem(
              'Date:',
              dateFormat(date, 'ddd, mmmm dd, yyyy'),
              'calendar',
            )}

            <FormField
              htmlFor="start-time"
              title="Start:"
            >
              <input
                id="start-time"
                type="time"
                className="form-control"
                value={start}
                onChange={(e) => this.setState({ start: e.target.value })}
              />
            </FormField>

            <FormField
              htmlFor="end-time"
              title="End:"
            >
              <input
                id="end-time"
                type="time"
                className="form-control"
                value={end}
                onChange={(e) => this.setState({ end: e.target.value })}
              />
            </FormField>

            {this.renderItem('Hourly Offer:', `$ ${rate}`, 'offer_rate')}
            {this.renderItem(
              'Meal Break:',
              breakTime
                ? `${breakTime} ${breakPaid ? '(Paid)' : '(Unpaid)'}`
                : 'Time',
              'break',
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 12,
              }}
            >
              {isLoading ? (
                <div>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="info"
                    style={{
                      height: 30,
                      width: 30,
                      marginLeft: 20,
                      marginTop: 10,
                    }}
                  />
                </div>
              )
                : (
                  <button
                    type="button"
                    onClick={this.createJob}
                    className="btn btn-info"
                    disabled={!this.isValid()}
                    style={{
                      width: 200,
                      borderRadius: 50,
                      height: 50,
                      fontSize: 20,
                    }}
                  >
                    {buttonTitle}
                  </button>
                )}
            </div>
          </div>
        </div>

        <MobileAppModal
          show={modalIsOpen}
          closeModal={this.closeModal}
          content={modalContent}
          result={this.modalResult}
        />
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['CREATE_JOB']);
const errorSelector = createErrorMessageSelector(['CREATE_JOB']);
const mapStateToProps = (state) => ({
  isLoading: loadingSelector(state),
  error: errorSelector(state),
  jobs: state.jobs,
  userEducation: state.users.userEducation,
});

export default connect(mapStateToProps, {
  createJob,
  getUserEducation,
})(JobCreateComponent);


/**
 * @param {string} title
 * @param {string} htmlFor
 * @param children
 * @param {'horizontal'|'vertical'|'split'} formFlow
 * @param {boolean} optional
 *
 * @returns {React.JSX.Element}
 */
function FormField({ title, htmlFor, children, formFlow = 'horizontal', optional = false }) {
  return (
    <div
      className={[
        'card card-body form-field',
        formFlow === 'vertical' && 'form-field__vertical',
        formFlow === 'split' && 'form-field__vertical form-field__split',
      ].join(' ')}
    >
      <label htmlFor={htmlFor} className="card-text">
        <span className="label-title">
          <b>{title}</b>

          {formFlow === 'horizontal' && <br/>}
          {optional && <span className="label-optional">{formFlow !== 'horizontal' && ' '}(Optional)</span>}
        </span>

        {
          children && (
            <div className="form-field-content">
              {children}
            </div>
          )
        }
      </label>
    </div>
  );
}
