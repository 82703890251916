import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Switch from 'react-switch';
import { lunchBreakItemsWithSuffix } from '../../constants';
import { Colors } from '../../themes/colors';
import '../../themes/global.scss';
import '../../themes/registration.scss';
import CustomButton from './buttons';
import { RadioOption } from './RadioOption';

export default class MobileAppModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: '',
      number: '',
      placeholder: 'Enter Phone Number',
      selectedDay: null,
      disabledBtn: false,
      selected_start_hour: '08',
      selected_end_hour: '05',
      selected_minutes: '00',
      time_of_day: '',
      selected_rate: 36,
      industrialAverage: 100.0,
      industrialAverageColor: 'green',
      selected_break_time: '',
      break_paid: false,
      checked: false,
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
      this.props.closeModal();
    }
  };

  submitDate = () => {
    if (this.state.selectedDay) {
      this.setState({ disabledBtn: true });
      this.props.result(this.state.selectedDay);
      this.props.closeModal();
    } else {
      alert('Please, select date');
    }
  };

  submitTime = (content) => {
    if (this.state.time_of_day.length < 1) {
      // AlertMessage({message: "Please select a time of day"})
    } else {
      this.props.result(
        `${this.state.selected_minutes} ${this.state.time_of_day}`,
      );
      this.setState({
        disabledBtn: true,
        selected_time: '',
        time_of_day: '',
      });
      this.props.closeModal();
    }
  };

  submitRate = () => {
    if (this.state.selected_rate) {
      this.props.result(Number(this.state.selected_rate));
      this.props.closeModal();
    } else {
      alert('Please, select rate');
    }
  };

  submitBreak = () => {
    if (this.state.selected_break_time) {
      const data = {
        time: this.state.selected_break_time,
        paid: this.state.break_paid,
      };
      this.props.result(data);
      this.setState({ disabledBtn: true });
      this.props.closeModal();
    } else {
      alert('Please, select Meal Break');
    }
  };

  submitPin = () => {
    // send verification code
    this.props.result(this.state.pin);
  };

  fixPhoneNumber(number) {
    if (number[0] === '(') {
      return `+1 ${number}`;
    }
    return number;
  }

  handleDayClick = (day, { selected }) => {
    this.setState({
      selectedDay: selected ? undefined : day,
      disabledBtn: false,
    });
  };

  setOfferRate(offerrate) {
    this.setState({ selected_rate: offerrate, disabledBtn: false });
    const percentage = (offerrate / 36) * 100;

    if (offerrate > 0 && offerrate <= 26) {
      this.setState({
        industrialAverage: parseFloat(percentage).toFixed(1),
        industrialAverageColor: 'red',
      });
    } else if (offerrate >= 27 && offerrate <= 32) {
      this.setState({
        industrialAverage: parseFloat(percentage).toFixed(1),
        industrialAverageColor: 'orange',
      });
    } else if (offerrate >= 33 && offerrate <= 54) {
      this.setState({
        industrialAverage: parseFloat(percentage).toFixed(1),
        industrialAverageColor: 'green',
      });
    } else if (offerrate >= 55 && offerrate <= 64) {
      this.setState({
        industrialAverage: parseFloat(percentage).toFixed(1),
        industrialAverageColor: 'orange',
      });
    } else if (offerrate >= 66 && offerrate <= 72) {
      this.setState({
        industrialAverage: parseFloat(percentage).toFixed(1),
        industrialAverageColor: 'red',
      });
    }
  }

  handleChange = (break_paid) => {
    this.setState({ break_paid, disabledBtn: false });
  };

  showMobile() {
    return (
      <div className="modal">
        <div className="modal_content">
          <p style={Styles.closePopup} onClick={this.props.closeModal}>
            X
          </p>
          <h1 className="blue_font" style={{ fontSize: 36, margin: 0 }}>
            GO MOBILE
          </h1>
          <h2
            style={{
              fontSize: 30,
              color: '#435963',
              fontFamily: 'Nunito',
            }}
          >
            Get The TempMee App Now
          </h2>
          <div
            style={{
              margin: 15,
              display: 'flex',
              justifyContent: 'space-between',
              width: 500,
            }}
          >
            <input
              className="input_phone"
              style={Styles.input}
              placeholder={this.state.placeholder}
              value={this.state.number}
              onChange={(e) => this.changeNumber(e.target.value)}
            />
            <CustomButton
              width={200}
              name="Get Download Link"
              fontSize={15}
              color="#307F92"
              class_name="button"
              click={this.sendLink}
            />
          </div>
        </div>
      </div>
    );
  }

  confirmSMS() {
    return (
      <div className="modal">
        <div className="modal_content" style={{ justifyContent: 'flex-start' }}>
          <h1 className="blue_font" style={{ fontSize: 36, marginTop: 40 }}>
            Send SMS
          </h1>
          <h4>{this.props.countText}</h4>
          {this.props.children}
          <p
            className="global_font f-dark"
            style={{
              marginTop: 30,
              marginLeft: 10,
              marginRight: 10,
              textAlign: 'left',
            }}
          >
            Send sms to:
            {' '}
            <strong>{this.props.smsPhone}</strong>
            <br />
            SMS body:
            {' '}
            <br />
            <textarea
              name="Text2"
              style={{
                width: '100%',
                height: '90%',
                marginLeft: '12px',
                fontSize: '12px',
                width: '500px',
              }}
              value={
                this.props.smsText
                // .split('\n')
                // .map((item, i) => {
                //     return <p key={i}>{item}</p>;
                // })
              }
              onChange={(newValue) => {
                this.setState({ currentBodyTextSMS: newValue });
                this.props.callbackChangeText(newValue.target.value);
              }}
            />
            <br />
            {this.props.customerNotesText === '' ? null : (
              <strong style={{ color: 'red' }}>
                Notes:
                {this.props.customerNotesText}
              </strong>
            )}
            <br />
            {this.props.adminNotes === '' ? null : (
              <strong style={{ color: 'red' }}>
                Admin Notes:
                {this.props.adminNotes}
              </strong>
            )}
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 10,
            }}
          >
            <div style={{ margin: 8 }}>
              <CustomButton
                width={200}
                name="Cancel"
                fontSize={16}
                color="#cdcdcd"
                class_name="button"
                click={() => this.props.callbackCancel()}
              />
            </div>

            <div style={{ margin: 8 }}>
              <CustomButton
                width={200}
                name="Skip"
                fontSize={16}
                color="#cdcdcd"
                class_name="button"
                click={() => this.props.callbackSkip()}
              />
            </div>

            {this.props.showLoader ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',

                  alignContent: 'center',
                  height: 30,
                  width: 200,
                }}
              >
                <Spinner
                  animation="border"
                  role="status"
                  variant="info"
                  style={{
                    marginTop: 25,
                    height: 30,
                    width: 30,
                    marginBottom: 20,
                  }}
                />
              </div>
            ) : (
              <div style={{ margin: 8 }}>
                <CustomButton
                  width={200}
                  name="Confirm"
                  fontSize={16}
                  color="#307F92"
                  class_name="button"
                  click={() => this.props.callbackSubmit()}
                />
              </div>
            )}
          </div>
          <div style={{ marginTop: 10, marginBottom: 20 }}>
            {' '}
            {this.props.errorText === '' ? null : (
              <h4 style={{ color: 'red' }}>
                {' '}
                {this.props.errorText}
              </h4>
            )}
          </div>
        </div>
      </div>
    );
  }

  showCalendar() {
    const someDate = new Date();
    const numberOfDaysToAdd = 90;
    someDate.setDate(someDate.getDate() + numberOfDaysToAdd);

    return (
      <div className="modal">
        <div className="modal_content" ref={this.modalRef}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              position: 'relative',
            }}
          >
            <h1
              className="blue_font"
              style={{
                fontSize: 24,
                color: '#435963',
                margin: 25,
                width: '100%',
                textAlign: 'center',
                marginBottom: 32,
              }}
            >
              Select Date
            </h1>
            <p style={Styles.closePopup} onClick={this.props.closeModal}>
              X
            </p>
          </div>
          <div
            style={{
              margin: '5px 10px',
              height: 300,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DayPicker
              onDayClick={this.handleDayClick}
              initialMonth={new Date()}
              selectedDays={this.state.selectedDay}
              disabledDays={{
                before: new Date(),
                after: someDate,
              }}
            />
          </div>
          <div style={{ margin: '24px 0px', marginTop: 32 }}>
            <CustomButton
              width={350}
              name="Confirm"
              color="#307F92"
              class_name="button"
              click={this.submitDate}
              btnDisabled={this.state.disabledBtn}
            />
          </div>
        </div>
      </div>
    );
  }


  hourlyRate() {
    const rates = [];
    for (let i = 18; i <= 200; i++) {
      rates.push(i);
    }
    return (
      <div className="modal">
        <div className="modal_content" ref={this.modalRef}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              position: 'relative',
              width: '100%',
            }}
          >
            <h1
              className="blue_font"
              style={{
                color: '#435963',
                fontSize: 24,
                margin: 25,
                width: '100%',
                textAlign: 'center',
              }}
            >
              Select Offer Rate
            </h1>
            <p style={Styles.closePopup} onClick={this.props.closeModal}>
              X
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <h1 className="global_font f-dark" style={{ fontWeight: 400, marginRight: 10 }}>
              $
            </h1>
            <select
              className="select-box f-dark"
              value={this.state.selected_rate}
              style={{
                padding: '7.5px 20px',
                width: 130,
                outline: 'none',
              }}
              onChange={(e) => this.setOfferRate(e.target.value)}
            >
              {rates.map((rate, index) => (
                <option className="options" value={rate} key={index}>
                  {rate}
                  {' '}
                  . 00
                </option>
              ))}
            </select>
          </div>
          <div style={{ display: 'flex' }}>
            <h3
              className="bold global_font f-dark"
              style={{
                color: this.state.industrialAverageColor,
                marginRight: 5,
                fontSize: 20,
              }}
            >
              {this.state.selected_rate ? `${this.state.industrialAverage}%` : '0%'}
            </h3>
            <h3 className="global_font f-dark" style={{ fontSize: 20 }}>
              of Industry average
            </h3>
          </div>
          <div style={{ margin: '20px 0px' }}>
            <CustomButton
              width={350}
              name="Confirm"
              color="#307F92"
              class_name="button"
              click={(_) => this.submitRate()}
              btnDisabled={this.state.disabledBtn}
            />
          </div>
        </div>
      </div>
    );
  }

  breakTime() {
    const { selected_break_time, break_paid } = this.state;
    return (
      <div className="modal">
        <div className="modal_content" ref={this.modalRef}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
              width: '100%',
              marginTop: '12px',
            }}
          >
            <h1
              className="blue_font"
              style={{
                fontSize: 24,
                margin: 25,
                width: '100%',
                color: '#435963',
                textAlign: 'center',
              }}
            >
              Select Meal Break
            </h1>
            <p style={Styles.closePopup} onClick={this.props.closeModal}>
              X
            </p>
          </div>

          <div style={{ margin: '5px 10px', display: "flex", flexDirection: "column", gap: 16 }}>
            {lunchBreakItemsWithSuffix.map((time) => (
              <div key={time}>
                <RadioOption
                  onClick={() => this.setState({
                    selected_break_time: time,
                    disabledBtn: false,
                  })}
                  text={time}
                  checked={time === selected_break_time}
                />
              </div>
            ))}
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginTop: 16, marginBottom: 8 }}>
            <Switch
              checked={break_paid}
              onChange={this.handleChange}
              onColor={Colors.secondary_500}
              offColor={Colors.neutral_100}
              onHandleColor={Colors.white}
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.35)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={25}
              width={50}
              className="react-switch"
              id="material-switch"
            />
            <span className="f-18 f-dark bold global_font" style={{ marginLeft: 10 }}>
              Meal Break is Paid
            </span>
          </div>

          <div style={{ margin: '20px 0px' }}>
            <CustomButton
              width={350}
              name="Confirm"
              color="#307F92"
              class_name="button"
              click={this.submitBreak}
              btnDisabled={this.state.disabledBtn}
            />
          </div>
        </div>
      </div>
    );
  }

  confirmYourPhoneNumber() {
    return (
      <div
        className="modal"
        style={{
          height: this.props.content === 'pin' ? 1083 : this.props.height,
        }}
      >
        <div className="modal_content" style={{ justifyContent: 'flex-start' }}>
          <h1 className="blue_font" style={{ fontSize: 36, margin: 50, color: '#435963',}}>
            Check Your Phone
          </h1>
          <p className="global_font f-dark" style={{ margin: 0, textAlign: 'center' }}>
            We sent you a 5 digit confirmation code
            <br />
            to:
            {' '}
            <strong>{this.props.number}</strong>
          </p>
          <input
            className="input_registration"
            style={{
              width: 300,
              marginTop: 20,
              textAlign: 'center',
              fontSize: 22,
            }}
            placeholder="XXXXX"
            maxLength={5}
            value={this.state.pin}
            onChange={(e) => this.setState({ pin: e.target.value })}
          />
          <div style={{ marginTop: 25 }}>
            <CustomButton
              width={200}
              name="Confirm"
              fontSize={16}
              color="#307F92"
              class_name="button"
              click={this.submitPin}
            />
          </div>
          <p
            className="input_names"
            style={{
              fontSize: 36,
              fontWeight: 600,
              color: '#B0BEC5',
              cursor: 'pointer',
              position: 'absolute',
              margin: '65px 0px 0px 260px',
              width: 24,
            }}
            onClick={this.props.closeModal}
          >
            X
          </p>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.show) {
      switch (this.props.content) {
      case 'mobile':
        return this.showMobile();
      case 'calendar':
        return this.showCalendar();
      case 'offer_rate':
        return this.hourlyRate();
      case 'break':
        return this.breakTime();
      case 'pin':
        return this.confirmYourPhoneNumber();
      case 'confirmSMS':
        return this.confirmSMS();
      default:
        return 'default';
      }
    } else {
      return <div />;
    }
  }
}

const Styles = {
  input: {
    width: 250,
    marginTop: 7,
    fontSize: 20,
    height: 32,
    padding: '0px 0px 10px 0px',
    borderRadius: 0,
  },

  closePopup: {
    position: 'absolute',
    right: 32,
    marginTop: -10,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#B0BEC5',
    cursor: 'pointer',
  },
};
