import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeJobCounterCover, sendJobCounterCover } from '../../../actions/paymnetsAction';
import CounterCoverPopup from './CounterCoverPopup';
import { Table } from './Table';
import CustomPortal from '../../commonComponents/CustomPortal';

const styles = {
  tableBoldText: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    width: '20%',
    textAlign: 'center',
  },
};

export default ({ transactionList, showRefundPopUp }) => {
  const dispatch = useDispatch();
  const currentJob = useSelector((state) => state.jobs.currentJob);
  const [showCounterCoverPopup, setShowCounterCoverPopup] = useState(false);
  const [showRemoveCounterCoverPopup, setRemoveShowCounterCoverPopup] = useState(false);

  const handleShowRefundPopup = (transaction) => {
    showRefundPopUp(transaction.metadata?.payment?.transaction_id, transaction.entries);
  };

  const handleSubmitCounterCover = (amount) => {
    dispatch(sendJobCounterCover(currentJob.id, amount));
    setShowCounterCoverPopup(false);
  };

  const handleRemoveCounterCover = () => {
    dispatch(removeJobCounterCover(currentJob.id));
    setRemoveShowCounterCoverPopup(false);
  };

  return (
    <div
      className="card mt-5 global_font"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '80%',
        margin: '50px',
      }}
    >
      <div
        className="card-body"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2
          className="card-title"
          style={{ marginLeft: 10, color: '#494963' }}
        >
          Transactions
        </h2>
        <div
          style={{
            display: 'flex',
            gap: 16,
          }}
        >
          <>
            {!currentJob?.counterCover && ['open', 'pending', 'counter'].includes(currentJob?.status) && (
              <button
                type="button"
                className="btn btn-info"
                onClick={() => setShowCounterCoverPopup(true)}
              >
                Counter Cover
              </button>
            )}
            
            {currentJob?.counterCover && (
              <div
                style={{
                  display: 'flex',
                  gap: 16,
                  alignItems: 'center',
                }}
              >
                <b
                  style={{
                    fontSize: 16,
                  }}>
                  ${currentJob.counterCover?.amount?.toFixed(2)} Counter cover applied
                </b>
                {['open', 'pending', 'counter'].includes(currentJob?.status) && (
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => setRemoveShowCounterCoverPopup(true)}
                  >
                    Remove Counter
                  </button>
                )}
              </div>
            )}
          </>
          <div
            style={{
              backgroundColor: 'gray',
              height: 40,
              width: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 20,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <h3
              className="card-title"
              style={{
                color: '#fff',
                margin: 0,
                padding: 0,
              }}
            >
              {transactionList?.length}
            </h3>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          borderBottom: '1px solid #ededed',
          flex: 1,
          margin: '0 20px',
        }}
      >
        <b
          style={{
            width: '30%',
            textAlign: 'center',
          }}
        >
          Date
        </b>

        <span style={styles.tableBoldText}>Type</span>

        <span style={styles.tableBoldText}>Brand & Last 4</span>

        <span style={styles.tableBoldText}>Label</span>

        <b
          style={{
            width: '40%',
            textAlign: 'center',
          }}
        >
          Amount
        </b>
        <span style={styles.tableBoldText}>Status</span>
        <span style={styles.tableBoldText}>Action</span>
      </div>

      {transactionList?.length === 0 ? (
        <div
          style={{
            height: 200,
            overflowY: 'auto',
            borderBottom: '1px solid #efefef',
          }}
        >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <span
              style={{
                fontSize: 18,
                alignText: 'center',
              }}
            >
              No Transactions
            </span>
          </div>
        </div>
      ) : (
        <Table transactionList={transactionList} handleShowRefundPopup={handleShowRefundPopup} />
      )}

      {showCounterCoverPopup && (
        <CounterCoverPopup
          onClose={() => setShowCounterCoverPopup(false)}
          onSubmit={handleSubmitCounterCover}
        />
      )}

      {showRemoveCounterCoverPopup && (
        <CustomPortal
          title="Remove Counter Cover"
          text="Are you sure you would like to remove this counter cover?"
          cancelText='Go Back'
          submitText='Remove Counter'
          cancelPressed={() => setRemoveShowCounterCoverPopup(false)}
          submitPressed={handleRemoveCounterCover}
        />
      )}
    </div>
  );
};
